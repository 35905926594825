import React, { Component } from "react"

import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { Fade } from "react-reveal"



export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      urlparams: null // Initialize to null
    };
  }

  componentDidMount() {
    // Check if window is available (client side)
    if (typeof window !== "undefined") {
      this.setState({
        urlparams: new URLSearchParams(window.location.search)
      });
    }
  }
  render() {
    const { urlparams } = this.state; // Destructure urlparams

    return (
      <>
      <StaticQuery query={ graphql`
    query{
        sanityCompanyInfo {
            companyname
            licenses
            logo {
              asset {
                fluid(maxWidth: 700) {
                  ...GatsbySanityImageFluid
                }
              }
            }
        }
        allSanityBadges{
          edges {
            node {
              badge_img {
                asset {
                  fluid {
                    src
                  }
                }
              }
              badge_name
            }
          }
        }
      }
    `}

    render={data => (
      <>
    <footer className="footer">
      
       <div className="badgeBanner">
          <div className="columns">
            {/* <Fade bottom cascade> */}
              <div className="badges">
                {data.allSanityBadges.edges.map(({ node: badge }) => (
                    <Image fluid={badge.badge_img.asset.fluid} key={badge.badge_name}/>
                  ))}
              </div>     
              {/* </Fade> */}
                  </div>
                </div>
              <div className="licenses">
                  {data.sanityCompanyInfo.licenses.map(( license  => 
                    <div>{license}</div>
                  ))}
              </div>
        <div className="container">
        <div className="footer-nav">
      <ul className="footer-nav">
          <li><a href={"/about-us" + (urlparams ? "?" + urlparams : "")}>About Us</a></li>
          <li><a href={"/our-services" + (urlparams ? "?" + urlparams : "")}>Our Services</a></li>
          <li><a href={"/coupons" + (urlparams ? "?" + urlparams : "")}>Specials</a></li>
          <li><a href={"/reviews" + (urlparams ? "?" + urlparams : "")}>Reviews</a></li>
          <li><a href={"/contact-us" + (urlparams ? "?" + urlparams : "")}>Contact Us</a></li>

        </ul>
      </div>
        <Image fluid={data.sanityCompanyInfo.logo.asset.fluid}
                  style={{ height: "auto", width: "200px" }}
                  className="align-center logo"
                  alt="Logo"
                />
<div className="address">
<p>Summers of Peru Inc, dba Summers Plumbing Heating & Cooling</p>
<p>2589 S Business 31 </p>
<p>Peru, IN 46970</p>
</div>

          <p>&copy; Summers of Peru Inc, dba Summers Plumbing Heating & Cooling | Marketing by <a href="http://vitalstorm.com/" target="_blank" rel="noopener noreferrer">VitalStorm</a></p> 
          <div className="footer-links">
            <a href="/privacy-policy" style={{textDecoration: "underline"}}>Privacy Policy</a> | 
            <a href="/terms-conditions" style={{textDecoration: "underline"}}>Terms & Conditions</a>
          </div>
        </div>
        
              <script src="https://s3.amazonaws.com/vs.static-files/vs_lp_conv_bundle.js"  async defer onload="SETUP_VS_LP();"></script>
      </footer>
      </>
          )}
        />
      </>
    )
  }
}




         
            
            
            
            
            
